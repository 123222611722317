import React from 'react';

const Header = () => (
  <header className="bg-light py-3 mb-4 border-bottom">
    <div className="container">
      <h1 className="text-center">Recipe Website</h1>
    </div>
  </header>
);

export default Header;
