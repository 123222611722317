import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getCookie } from '../Auth';

const RecipeList = ({ onSelect }) => {
    const [recipes, setRecipes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRecipes = async () => {
            try {
                const token = getCookie('token');
                const response = await axios.get(`https://www.taskbyteportal.com/api/recipes`, {
                    withCredentials: true,
                });
                setRecipes(response.data);
            } catch (error) {
                setError('Failed to fetch recipes');
            } finally {
                setLoading(false);
            }
        };

        fetchRecipes();
    }, []);

    if (loading) return <p>Loading recipes...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="container">
            <div className="row">
                {recipes.map((recipe, index) => (
                    <div
                        key={index}
                        onClick={() => onSelect(recipe.id)}
                        className="col-md-4 mb-3"
                    >
                        <div className="card h-100">
                            <img src={recipe.image} alt={recipe.name} className="card-img-top" />
                            <div className="card-body">
                                <h3 className="card-title">{recipe.name}</h3>
                                <p className="card-text">{recipe.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecipeList;
