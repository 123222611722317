import React, { useState } from 'react';
import axios from 'axios';

const AddRecipeForm = ({ onAddRecipe }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [instructions, setInstructions] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImage(file);
        setImagePreview(URL.createObjectURL(file));
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('ingredients', ingredients);
    formData.append('instructions', instructions);
    if (image) {
        formData.append('image', image);
    }

    try {
        const token = localStorage.getItem('token');
        const response = await axios.post('https://www.taskbyteportal.com/api/recipes', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        onAddRecipe(response.data);
        setName('');
        setDescription('');
        setIngredients('');
        setInstructions('');
        setImage(null);
    } catch (error) {
        console.error('Failed to add recipe:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mt-4">
      <div className="mb-3">
        <label className="form-label">Recipe Name</label>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Description</label>
        <input
          type="text"
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Ingredients (comma separated)</label>
        <input
          type="text"
          className="form-control"
          value={ingredients}
          onChange={(e) => setIngredients(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Instructions</label>
        <textarea
          rows={3}
          className="form-control"
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Upload Image</label>
        <input
          type="file"
          className="form-control"
          accept="image/"
          onChange={handleImageChange}
        />
        {imagePreview && (
            <div className="mt-3">
              <img src={imagePreview} alt="Preview" className="img-fluid" />
            </div>
        )}
      </div>
      <button type="submit" className="btn btn-primary">
        Add Recipe
      </button>
    </form>
  );
};

export default AddRecipeForm;
