import { useLocation, Navigate } from "react-router-dom";

export const setToken = (token) => {
    localStorage.setItem('my_user', token);
}

export const fetchToken = (token) => {
    return localStorage.getItem('my_user');
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function RequireToken({children}) {
    let auth = fetchToken()
    let location = useLocation()

    if (!auth) {
        return <Navigate to='/' state = {{from : location}}/>;
    }

    return children;
}