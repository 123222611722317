import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import RecipeList from './components/RecipeList';
import RecipeDetail from './components/RecipeDetail';
import AddRecipeForm from './components/AddRecipeForm';
import Login from './components/Login';
import Register from './components/Register';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css'; // Optional for additional custom styles

const App = () => {
  const [recipes, setRecipes] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [auth, setAuth] = useState(localStorage.getItem('token') ? true : false);

  const addRecipe = (recipe) => {
    setRecipes([...recipes, recipe]);
  };

  return (
      <Routes>
        <Route path="/" element = {<Login setAuth={setAuth}/>}/>
        <Route path="*" element={<Navigate to="/"/>}/>
        <Route path="register" element={<Register />} />
        <Route 
          path="/dashboard" 
          element = {
            <ProtectedRoute>
              <div className="app">
                <Header />
                <div className="content">
                  {selectedRecipe === null ? (
                    <>
                      <RecipeList recipes={recipes} onSelect={setSelectedRecipe}/>
                      <h3 className="text-center mt-4">Add a New Recipe</h3>
                      <AddRecipeForm onAddRecipe={addRecipe} />
                    </>
                  ) : (
                    <RecipeDetail recipeId={selectedRecipe} onBack={() => setSelectedRecipe(null)} />
                  )}
                </div>
              </div>
            </ProtectedRoute>
          }/>
      </Routes>
  );
};

export default App;
