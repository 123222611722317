import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RecipeDetail = ({ recipeId, onBack }) => {
    const [recipe, setRecipe] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`https://www.taskbyteportal.com/api/recipes/${recipeId}`, {
                    withCredentials: true,
                });
                console.log(response.data)
                setRecipe(response.data);
            } catch (error) {
                setError('Failed to fetch recipe details');
            } finally {
                setLoading(false);
            }
        };

        fetchRecipe();
    }, [recipeId]);

    if (loading) return <p>Loading recipe...</p>;
    if (error) return <p>{error}</p>;
    if (!recipe) return <p>No recipe found</p>;

    return (
        <div className="container">
            <button className="btn btn-secondary mb-3" onClick={onBack}>
                Back
            </button>
            <div className="card">
                <img src={recipe.image} alt={recipe.name} className="recipe-detail-img" />
                <div className="card-body">
                    <h2 className="card-title">{recipe.name}</h2>
                    <p className="card-text">{recipe.description}</p>
                    <h4>Ingredients</h4>
                    <ul className="list-group mb-3">
                        {recipe.ingredients.map((ingredient, index) => (
                            <li className="list-group-item" key={index}>
                                {ingredient}
                            </li>
                        ))}
                    </ul>
                    <h4>Instructions</h4>
                    <p>{recipe.instructions}</p>
                </div>
            </div>
        </div>
    );
};

export default RecipeDetail;
